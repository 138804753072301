<template>
	<div class="page_feedback">
		<top :bgWhite='true'></top>
		<div class="contentAll  padding-lr-30  top-80">
			<div class="margin-top-20 bg-white padding ">
				<p class="fs16 bold text-333  solid-bottom padding-bottom-20">意见反馈</p>
				<p class="fs12 bold margin-bottom-10 margin-top-20">您的意见是我们前进的动力</p>
				<div class="input-boxs">
					<textarea rows="4" cols="" placeholder="请输入您的意见或反馈" v-model="body"></textarea>
				</div>
				<el-upload
				  class="margin-top-30"
				  :action="BaseUrl+'/api/v1/5fc615f7da92b'"
				  list-type="picture-card"
				  :on-preview="handlePictureCardPreview"
				  :on-remove="handleRemove"
				  :on-change="handleChange"
				  :limit="6"
				  :file-list="fileList">
				  <i class="el-icon-plus"></i>
				</el-upload>
				<el-dialog :visible.sync="dialogVisible">
					<img width="100%" :src="dialogImageUrl" alt="">
				</el-dialog>
				<!-- <el-upload class="avatar-uploader margin-top-20" :action="BaseUrl+'/api/v1/5fc615f7da92b'"
					:show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
					<img v-if="thumb" :src="thumb" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload> -->
				<!-- 完成按钮 -->
				<div class="margin-top-30 flex align-center justify-center">
					<el-button type="danger" round size="medium" @click="submitFeedback">提交反馈</el-button>
				</div>
			</div>
		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		components: {
			bottom,
			top
		},
		data() {
			return {
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				BaseUrl: this.imgUrl,
				body: '',
				thumb: '',
				fileList:[]
			}
		},
		methods: {
			// 删除
			handleRemove(file,fileList) {
				this.fileList = fileList;
			},
			// 大图预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleChange(file,fileList){
				this.fileList = fileList;
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			// 提交意见反馈
			submitFeedback() {
				this.thumb = this.fileList.map(item=>{
					return item.response.data.data.fileId
				});
				let imgurl = '';
				if(this.thumb.length>0){
					 imgurl = this.thumb.join(',')
				}
				if (!this.body) {
					this.$message.error('请先输入反馈内容');
					return;
				}
				let data = {
					body: this.body,
					thumb: imgurl
				}
				this.ajax('post', '/v1/5cc3f28296cf0', data, res => {
					console.log(res);
					this.hadGotCode = true
					if (res.code == 1) {
						this.body = '';
						this.$message.success(res.msg);
						this.$router.go(-1)
					} else {
						this.$message.error(res.msg);
					}
				}, err => {
					console.log(err);
				})
			}
		}
	}
</script>

<style lang="scss">
	html,
	body {
		background: #F6F7F9 !important;

		.content {
			width: 100%;
			background: #FFFFFF;
		}
	}

	.contentAll {
		width: 60%;
	}

	.input-boxs {
		width: 90%;
		height: 100%;

		textarea {
			width: 100%;
			height: 160px;
			background: #F6F7F9;
			opacity: 1;
			border: none;
			padding: 20px;
			border-radius: 5px;
		}
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 96px;
		height: 96px;
		line-height: 96px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
